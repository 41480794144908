import { css } from '@emotion/core'
import React from 'react'
import { cx } from '../utils/helpers'

type MilestonesContainerProps = {
  children: React.ReactNode
}

export function MilestonesContainer({
  children,
}: MilestonesContainerProps): React.ReactElement {
  return <ol>{children}</ol>
}

type MilestoneYearProps = {
  year: string
  children: React.ReactNode
}

export function MilestoneYear({
  year,
  children,
}: MilestoneYearProps): React.ReactElement {
  return (
    <li>
      <h3
        className={cx(
          'sticky z-10 top-8 inline-block w-12 py-1 bg-white shadow border border-gray-300 rounded text-center',
          'md:block md:w-16 md:mx-auto md:text-lg',
        )}
      >
        {year}
      </h3>
      <ul className="my-4">{children}</ul>
    </li>
  )
}

type MilestoneProps = {
  image?: React.ReactElement
  titleMarkdown: string
  bodyMarkdown?: string
  imagePosition: 'left' | 'right'
}

export function Milestone({
  image,
  titleMarkdown,
  bodyMarkdown,
  imagePosition = 'left',
}: MilestoneProps): React.ReactElement {
  return (
    <li
      className={cx(
        'pl-8 pt-4 pb-8 ml-6 border-l border-gray-300',
        'md:flex md:pl-0 md:py-0 md:ml-0 md:border-0',
        imagePosition === 'left' ? 'md:flex-row-reverse' : 'md:flex-row',
      )}
    >
      <div
        className={cx(
          'md:w-1/2 md:pt-8 md:pb-16 md:border-gray-300',
          imagePosition === 'left'
            ? 'md:pl-10 md:text-left md:border-l'
            : 'md:pr-10 md:text-right md:border-r',
        )}
      >
        <div
          className={cx(
            'max-w-sm',
            imagePosition === 'left' ? 'md:mr-auto' : 'md:ml-auto',
          )}
        >
          <h4
            className="text-xl md:text-2xl leading-snug"
            css={css`
              position: relative;

              /* dot marker next to title */
              ::before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                left: calc(-2rem - 1px);
                top: 0.45em;
                transform: translateX(-50%);
                width: 12px;
                height: 12px;
                border-radius: var(--rounded-full);
                background-color: white;
                border: solid 2px var(--color-accent-200);

                @media (min-width: 768px) {
                  ${imagePosition === 'left'
                    ? css`
                        left: -2.5rem;
                        transform: translateX(-50%);
                      `
                    : css`
                        left: unset;
                        right: -2.5rem;
                        transform: translateX(50%);
                      `}
                }
              }
            `}
          >
            <div
              // optical adjustment compared to image
              className="-mt-2"
              dangerouslySetInnerHTML={{ __html: titleMarkdown }}
            />
          </h4>

          {bodyMarkdown && (
            <div
              dangerouslySetInnerHTML={{ __html: bodyMarkdown }}
              className="mt-2 md:mt-4 text-gray-700 md:text-lg"
              css={css`
                p + p {
                  margin-top: 1.5em;
                }
              `}
            />
          )}
        </div>
      </div>

      {image && (
        <div
          className={cx(
            'mt-8 md:mt-0 md:w-1/2 md:pt-8 md:pb-16',
            imagePosition === 'left' ? 'md:pr-10' : 'md:pl-10',
          )}
        >
          {image}
        </div>
      )}
    </li>
  )
}
