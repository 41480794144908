import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React, { ReactElement } from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { Markdown } from '../components/Markdown'
import {
  Milestone,
  MilestonesContainer,
  MilestoneYear,
} from '../components/Milestones'
import { CalloutBlock } from '../components/modular-blocks/Callout.block'
import { PageH1 } from '../components/PageH1'
import { WidthWrapper } from '../components/WidthWrapper'
import { AboutPageQuery } from '../types/graphql'
import { cx } from '../utils/helpers'

type Props = {
  data: AboutPageQuery
}

export default function AboutPage({ data }: Props): ReactElement {
  const {
    seoMetaTags,
    introductionNode,
    stats,
    milestones,
    content,
  } = data.datoCmsAboutPage!
  let isImageLeft = false

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        <Breadcrumb>
          <BreadcrumbItem>About</BreadcrumbItem>
        </Breadcrumb>

        <PageH1>About</PageH1>

        <WidthWrapper>
          <section className="lg:flex lg:my-8">
            <div className="my-8 lg:my-0 lg:w-2/3 lg:pr-16">
              <h2 className="text-3xl text-accent-800 leading-tight">
                Introduction
              </h2>

              <div className="max-w-md mt-8 text-lg">
                <Markdown
                  innerHTML={introductionNode!.childMarkdownRemark!.html!}
                />
              </div>
            </div>

            <aside
              className={cx(
                'my-8 pt-8 border-t border-gray-200',
                'lg:my-0 lg:pt-0 lg:w-1/3 lg:px-12 lg:border-l lg:border-t-0',
                'lg:flex items-start',
              )}
            >
              <dl className="flex flex-wrap justify-center text-center lg:text-left lg:justify-start">
                {stats &&
                  stats.length &&
                  stats.map(stat => (
                    <div
                      key={stat!.attribute!}
                      className="p-4 w-1/3 lg:w-1/2 flex flex-col-reverse justify-end"
                    >
                      <dt className="leading-tight text-gray-700 mt-2">
                        {stat!.attribute}
                      </dt>
                      <dd className="text-3xl leading-none text-gray-700">
                        {stat!.value}
                      </dd>
                    </div>
                  ))}
              </dl>
            </aside>
          </section>

          <section className="pt-8 mt-8 border-t border-gray-300">
            <h2 className="text-3xl text-accent-800 leading-tight mb-8">
              Milestones
            </h2>

            <MilestonesContainer>
              {milestones!.map(milestoneYear => {
                const { year, milestoneList } = milestoneYear!

                return (
                  <MilestoneYear year={year!}>
                    {milestoneList!.map(milestone => {
                      const { image, titleNode, bodyNode } = milestone!
                      const fluidImage =
                        image && image.fluid ? (
                          <Img
                            fluid={image.fluid as FluidObject}
                            alt=""
                            className="rounded shadow-xl"
                          />
                        ) : (
                          undefined
                        )
                      const titleMarkdown =
                        titleNode &&
                        titleNode.childMarkdownRemark &&
                        titleNode.childMarkdownRemark.html
                      const bodyMarkdown =
                        bodyNode &&
                        bodyNode.childMarkdownRemark &&
                        bodyNode.childMarkdownRemark.html

                      isImageLeft = !isImageLeft

                      return (
                        <Milestone
                          image={fluidImage}
                          titleMarkdown={titleMarkdown!}
                          bodyMarkdown={bodyMarkdown || undefined}
                          imagePosition={isImageLeft ? 'left' : 'right'}
                        />
                      )
                    })}
                  </MilestoneYear>
                )
              })}
            </MilestonesContainer>
          </section>
        </WidthWrapper>

        {content && content.length > 0
          ? content.map(block => {
              if (!block) return null

              switch (block.__typename) {
                case 'DatoCmsCallout':
                  return <CalloutBlock data={block} />
                default:
                  const _exhaustiveCheck: never = block
                  return _exhaustiveCheck
              }
            })
          : null}
      </main>
    </>
  )
}

export const query = graphql`
  query AboutPageQuery {
    datoCmsAboutPage {
      seoMetaTags {
        tags
      }

      introductionNode {
        childMarkdownRemark {
          html
        }
      }

      stats {
        attribute
        value
      }

      milestones {
        ... on DatoCmsMilestoneYear {
          year
          milestoneList {
            image {
              fluid(maxWidth: 632) {
                ...DatoFluid
              }
            }
            titleNode {
              childMarkdownRemark {
                html
              }
            }
            bodyNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }

      content {
        __typename

        ... on DatoCmsCallout {
          ...CalloutBlockFragment
        }
      }
    }
  }
`
